<template>
    <div class="wrap">
        <div class="manageBox">
            <div class="top">
                <el-form :inline="true" :model="queryParams" label-width="100px" class="demo-form-inline">
                    <el-form-item label="入住机构">
                        <el-select class="w200px" v-model="queryParams.sysId" clearable placeholder="请选择入住机构">
                            <el-option v-for="item in sysList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="老人类型">
                        <el-select class="w200px" v-model="queryParams.mzeeType" clearable placeholder="请选择老人类型">
                            <el-option v-for="(item, index) in mzeeTypeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="性别">
                        <el-select class="w200px" v-model="queryParams.sex" clearable placeholder="请选择性别">
                            <el-option v-for="(item, index) in sexList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否离院">
                        <el-select class="w200px" v-model="queryParams.retreatType" clearable placeholder="请选择是否离院">
                            <el-option v-for="(item, index) in retreatTypeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="搜索内容">
                        <el-input v-model="queryParams.keyWord" placeholder="姓名/电话/身份证号" clearable style="width: 200px" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="search" @click="searchList">搜索</el-button>
                        <el-button type="primary" class="search" plain @click="resetHanlder">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="content">
                <el-table v-loading="loading" :data="tableData" border style="width: 100%" :header-cell-style="headerCellStyle">
                    <template slot="empty">
                        <IsEmpty />
                    </template>
                    <el-table-column type="index" label="序号" align="center" width="80" />
                    <el-table-column prop="userName" align="center" label="姓名" />
                    <el-table-column prop="sex" align="center" label="性别">
                        <template slot-scope="scope">
                            <span>
                                {{ ['女', '男', '未知'][scope.row.sex * 1] }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="old" align="center" label="年龄（岁）" />
                    <el-table-column prop="cardId" align="center" label="身份证号" width="200" />
                    <el-table-column prop="phone" align="center" label="联系电话" />
                    <el-table-column prop="mzeeType" align="center" label="老人类型">
                        <template slot-scope="scope">
                            <span>{{ ['', '本区老人', '非本区老人'][scope.row.mzeeType * 1] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sysName" align="center" label="入住机构" width="200" />
                    <el-table-column prop="bedNumber" align="center" label="床位号" />
                    <el-table-column prop="liveStartTime" align="center" label="入住时间" />
                    <el-table-column prop="retreatTime" align="center" label="离院时间" />
                    <el-table-column label="操作" align="center" fixed="right" min-width="90">
                        <template slot-scope="scope">
                            <el-button type="text" @click="see(scope.row)">查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    style="margin: 20px 0"
                    background
                    layout="total, sizes, prev, pager, next"
                    :total="total"
                    v-if="total > 0"
                    :page-size="queryParams.pageSize"
                    :current-page="queryParams.pageNum"
                    :page-sizes="[10, 20, 30, 40]"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { elderInfoList, examineSysList } from '@/api/institutionalSubsidies'

export default {
    data() {
        return {
            total: 0,
            loading: false,
            tableData: [],
            queryParams: {
                sysId: '',
                mzeeType: '',
                sex: '',
                retreatType: '',
                keyWord: '',
                pageNum: 1,
                pageSize: 10
            },
            sysList: [],
            mzeeTypeList: [
                { label: '本区老人', value: 1 },
                { label: '非本区老人', value: 2 }
            ],
            sexList: [
                { label: '女', value: 0 },
                { label: '男', value: 1 },
                { label: '未知', value: 2 }
            ],
            retreatTypeList: [
                { label: '是', value: 0 },
                { label: '否', value: 1 }
            ]
        }
    },
    mounted() {
        this.getSysList()
        this.getList()
    },
    methods: {
        async getSysList() {
            const res = await examineSysList()
            this.sysList = res.data
        },
        resetHanlder() {
            this.queryParams = {
                sysId: '',
                mzeeType: '',
                sex: '',
                retreatType: '',
                keyWord: '',
                pageNum: 1,
                pageSize: 10
            }
            this.getList()
        },
        see(row) {
            this.$router.push({
                path: '/elderlyFormInfo/elderlyFormInfoView',
                query: {
                    id: row.id
                }
            })
        },
        // 管理人员列表
        async getList() {
            this.loading = true
            try {
                const res = await elderInfoList(this.queryParams)
                this.total = res.data.total
                this.tableData = res.data.rows
                this.loading = false
            } catch (e) {
                this.loading = false
            }
        },
        searchList() {
            this.queryParams.pageNum = 1
            this.getList()
        },
        // 翻页
        handleCurrentChange(val) {
            this.loading = true
            this.queryParams.pageNum = val
            this.getList()
        },
        handleSizeChange(val) {
            this.loading = true
            this.queryParams.pageSize = val
            this.getList()
        }
    }
}
</script>

<style lang="scss" scoped>
.wrap {
    padding: 20px;
    background: #fff;
}
.manageBox {
    // margin: 20px;
    background: #fff;
    min-height: calc(100vh - 130px);
    .top {
        .search {
            margin-left: 20px;
        }
        .add {
            float: right;
        }
    }
    .eyes {
        width: 20px;
        height: 20px;
        float: right;
        margin-right: 20px;
        cursor: pointer;
    }
    .operation {
        margin-right: 20px;
        color: #3fcf89;
        cursor: pointer;
    }
    .stop {
        color: #f57473;
    }
    .widthStyle {
        width: 90%;
    }

    /*      /deep/ .el-form-item__content {
              margin-left: 55px !important;
              margin-right: 55px;
          }*/
}
.content {
    /*padding: 15px;*/
    padding-top: 20px;
}
.my_btn_duty {
    margin-right: 20px;
}
.el-button--text {
    outline: none;
    border: none;
}
.el-button--text:hover,
.el-button--text:focus {
    background: none !important;
    border: none !important;
    outline: none !important;
}
.el-button.is-disabled,
.el-button.is-disabled:hover,
.el-button.is-disabled:focus {
    color: #c0c4cc !important;
}
.w200px {
    width: 200px;
}
</style>
